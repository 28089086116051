html {
	background-color: #282c34;
}

.App {
	min-height: 100vh; 
	display: flex;
	flex-direction: column; 
	text-align: center;
	margin: 0;
	padding: 0;
}

body {
	margin: 0;
	padding: 0;
}

.App-logo {
	height: 25vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	/* background-color: #282c34; */
	min-height: initial; /* Adjust as needed */
	display: flex;
	flex-direction: column;
	align-items: center; /* Horizontally center content */
	/*align-items: flex-start; Align items to the start (left) of the container */
	justify-content: flex-start; /* Align items to the start/top of the container */
	font-size: calc(10px + 2vmin);
	color: white;
	padding: 15px; /* Adds space around the content */
}

.App-footer {
	color: white;
	margin-top: auto; /* Push the footer to the bottom */
	text-align: center; /* Center text horizontally */
	padding: 15px;
	background-color: #282c34; /* Add a background color if needed */
}

.App-link {
	color: #1a669a;
	font-size: 24px;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}
